@tailwind base;
@tailwind components;
@tailwind utilities;

.wrap{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.jb-division-line {
  border-top: 0.2rem solid #444444;
  margin: 30px 0px;
}
